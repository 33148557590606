import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout';
import { graphql } from "gatsby"
import { Container } from "@material-ui/core"

const PrivacyNew = ({ location, data }) => {
  let result = data.allMarkdownRemark.edges[0].node.html
  return (
    <Layout location={location}>
      <SEO title="Privacy" description="Privacy of blix" />
      <Container maxWidth="lg">
        <div className="new-privacy">
          <div
            style={{ color: "#000" }}
            dangerouslySetInnerHTML={{ __html: result }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default PrivacyNew

export const pageQuery = graphql`
  query PrivacyIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "privacy" } } }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            templateKey
          }
        }
      }
    }
  }
`
